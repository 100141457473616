import { PreviewFlippableCard } from "@/components/card/PreviewFlippableCard";
import { sendGAEvent } from "@next/third-parties/google";
import { useV1ServiceV1OrderManagementWorkspaceCartGet } from "common/api/queries";
import useAuth from "hooks/useAuth";
import { LoadingIndicator, Button, ConfirmModal } from "legend-ui";
import { useState, useEffect } from "react";
import { useOrderFormStore } from "store/order-form/order-form";
import ButtonFooter from "../button-footer";
import { SetContainer } from "../set-container";
import StepHeader from "../step-header";
import Container from "@/components/containers/container";
import Link from "next/link";
import {
  WorkspaceCardSetResponse,
  WorkspaceCardSetTemplateVariantBaseResponse
} from "common/api/requests";
import useResetCard from "hooks/card/use-reset-card";
import { isDefaultDesignForSet } from "@/utils/reward";
import { sendMetaEvent } from "@/utils/send-meta-event";

type SelectedSetInfo = {
  id: string;
  variant: string;
};

type ConfirmingSetSelection = {
  setId: string;
  setName: string;
  variantName: string;
};

type Props = {
  onNext: () => void;
  sets: WorkspaceCardSetResponse[];
  setsLoading: boolean;
  refetchSets: () => void;
};

export const PickASet = ({ onNext, sets: allSets, setsLoading, refetchSets }: Props) => {
  const { isLoadingSession, session } = useAuth();
  const workspaceId = session.workspaceId;
  const { data: cartData } = useV1ServiceV1OrderManagementWorkspaceCartGet({
    workspaceId,
    page: 1
  });
  const { reset } = useResetCard(allSets);
  const [confirmSelectReset, setConfirmSelectReset] = useState<ConfirmingSetSelection>();
  const hasItemsInCart = cartData?.cart_items?.length > 0;
  const {
    cardStepData: { pickASetStep, attributesStep, photoStep },
    setCardStep,
    setWorkspaceId
  } = useOrderFormStore();
  const defaultSeasonResult = allSets?.[0];
  const defaultSeasonResultVariant = defaultSeasonResult?.card_set_template_variant_bases.find(
    (variant) => variant.default
  );
  const defaultSelectedSetId = pickASetStep?.cardSetId;
  const defaultSelectedVariant = pickASetStep?.cardSetVariantName;
  const hasSelectedDefault = defaultSelectedSetId && defaultSelectedVariant;
  const [selectedSetInfo, setSelectedSetInfo] = useState<SelectedSetInfo>(
    hasSelectedDefault
      ? {
          id: defaultSelectedSetId,
          variant: defaultSelectedVariant
        }
      : undefined
  );
  const userSelectedSet = allSets?.find((result) => result.id === selectedSetInfo?.id);
  const userSelectedVariant = userSelectedSet?.card_set_template_variant_bases?.find(
    (variant) => variant.name === selectedSetInfo?.variant
  );
  const selectedSet = userSelectedSet ?? defaultSeasonResult;
  const selectedVariant = userSelectedVariant ?? defaultSeasonResultVariant;
  const isCurrentlyApplied = (variant: WorkspaceCardSetTemplateVariantBaseResponse) =>
    photoStep &&
    selectedSet &&
    photoStep?.setId === selectedSet?.id &&
    photoStep.template === variant.card_set_template &&
    photoStep.variant === variant.card_variant_base;
  const disableNext =
    !selectedSet ||
    !selectedVariant ||
    (!isCurrentlyApplied(selectedVariant) && selectedVariant.locked);

  const handleNext = () => {
    setWorkspaceId(workspaceId);
    const attributesSetId = attributesStep?.cardSetId;
    if (attributesStep && attributesSetId !== selectedSet.id) {
      setCardStep({
        attributesStep: undefined
      });
    }
    setCardStep({
      pickASetStep: {
        cardSetId: selectedSet.id,
        cardSetVariantName: selectedVariant.name,
        cardSetTemplate: selectedVariant.card_set_template,
        cardVariantBase: selectedVariant.card_variant_base
      }
    });
    sendGAEvent("event", "select_item", {
      items: [
        {
          item_id: selectedSet.id,
          item_name: selectedSet.name,
          item_variant: selectedVariant.name
        }
      ]
    });
    sendMetaEvent("track", "CustomizeProduct");
    onNext();
  };

  const onSetSelectWithConfirm = (setId: string, setName: string, variantName: string) => {
    const hasGeneratedCard = photoStep?.cardId;
    const generatedWithAttributes = photoStep?.attributes ?? [];
    if (
      hasGeneratedCard &&
      (generatedWithAttributes.length > 0 ||
        !isDefaultDesignForSet(allSets, photoStep?.setId, photoStep?.template, photoStep?.variant))
    ) {
      setConfirmSelectReset({
        setId,
        setName,
        variantName
      });
      return;
    }
    onSetSelect(setId, setName, variantName);
  };

  const onSetSelect = (setId: string, setName: string, variantName: string) => {
    setSelectedSetInfo({
      id: setId,
      variant: variantName
    });
    sendGAEvent("event", "view_item", {
      items: [
        {
          item_id: setId,
          item_name: setName,
          item_variant: variantName
        }
      ]
    });
  };

  const onSetSelectWithReset = () => {
    reset();
    refetchSets();
    onSetSelect(
      confirmSelectReset.setId,
      confirmSelectReset.setName,
      confirmSelectReset.variantName
    );
    setConfirmSelectReset(undefined);
  };

  useEffect(() => {
    if (defaultSeasonResult && defaultSeasonResultVariant && !userSelectedSet) {
      sendGAEvent("event", "view_item", {
        items: [
          {
            item_id: defaultSeasonResult.id,
            item_name: defaultSeasonResult.name,
            item_variant: defaultSeasonResultVariant.name
          }
        ]
      });
    }
  }, [defaultSeasonResult, defaultSeasonResultVariant]);

  return (
    <Container innerClassName="pt-0 px-0">
      <div className="flex flex-col gap-6 md:flex-row lg:gap-x-20">
        {isLoadingSession && <LoadingIndicator variant="black" />}
        <div className="flex flex-grow flex-col">
          <StepHeader
            title={"Pick a design"}
            subtitle={"Choose a set for your card design"}
            frontUrl={selectedVariant?.card_sample_front_url}
            backUrl={selectedVariant?.card_sample_back_url}
          />
          <div className="flex flex-col-reverse items-start px-4 md:flex-row md:items-start md:justify-between md:space-x-16 md:px-0 md:py-2">
            <div className="flex w-full flex-col justify-center md:w-auto">
              <div className="md:pt-2">
                {setsLoading ? (
                  <LoadingIndicator variant="black" />
                ) : (
                  allSets?.map((set) => {
                    return (
                      <SetContainer
                        key={set.id}
                        setTitle={set.name}
                        previews={
                          set.card_set_template_variant_bases?.map((variant) => ({
                            src: variant.card_icon_url,
                            alt: variant.name,
                            name: variant.name,
                            locked: variant.locked && !isCurrentlyApplied(variant),
                            count: !variant.default && variant.count > 0 ? variant.count : undefined
                          })) ?? []
                        }
                        onSetClick={(preview) =>
                          onSetSelectWithConfirm(set.id, set.name, preview.name)
                        }
                        selectedVariants={set.id === selectedSet?.id ? [selectedVariant.name] : []}
                      />
                    );
                  })
                )}

                <div className="my-6 flex w-64 items-center justify-center rounded-full bg-[#00cdff] py-0.5 text-sm font-bold text-white">
                  <div className="font-zendots font-normal">more sets to come soon!</div>
                </div>
              </div>
              <ButtonFooter>
                {hasItemsInCart && (
                  <Link href="/review">
                    <Button variant="outline">Review order</Button>
                  </Link>
                )}
                <Button className="flex-grow md:grow-0" onClick={handleNext} disabled={disableNext}>
                  Next
                </Button>
              </ButtonFooter>
            </div>
          </div>
        </div>

        <div className="hidden items-start md:flex md:flex-col">
          {selectedVariant && (
            <PreviewFlippableCard
              frontUrl={selectedVariant?.card_sample_front_url}
              backUrl={selectedVariant?.card_sample_back_url}
            />
          )}
        </div>
        <ConfirmModal
          title="Change design?"
          description="The special design and attributes applied to your current card will be lost."
          onConfirm={onSetSelectWithReset}
          onCancel={() => setConfirmSelectReset(undefined)}
          open={!!confirmSelectReset}
        />
      </div>
    </Container>
  );
};