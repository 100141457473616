interface LockedIconProps {
  // eslint-disable-next-line react-redux/no-unused-prop-types
  className?: string;
}

export const LockedIcon = (props?: LockedIconProps) => {
  const { className } = props || {};
  return (
    <svg
      className={className}
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 8V7C17 5.14348 16.2625 3.36301 14.9497 2.05025C13.637 0.737498 11.8565 0 10 0C8.14348 0 6.36301 0.737498 5.05025 2.05025C3.7375 3.36301 3 5.14348 3 7V8C2.20435 8 1.44129 8.31607 0.87868 8.87868C0.316071 9.44129 0 10.2044 0 11V21C0 21.7956 0.316071 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H17C17.7956 24 18.5587 23.6839 19.1213 23.1213C19.6839 22.5587 20 21.7956 20 21V11C20 10.2044 19.6839 9.44129 19.1213 8.87868C18.5587 8.31607 17.7956 8 17 8ZM11 15.72V19C11 19.2652 10.8946 19.5196 10.7071 19.7071C10.5196 19.8946 10.2652 20 10 20C9.73478 20 9.48043 19.8946 9.29289 19.7071C9.10536 19.5196 9 19.2652 9 19V15.72C8.61872 15.4999 8.32072 15.1601 8.15224 14.7533C7.98376 14.3466 7.9542 13.8956 8.06815 13.4703C8.1821 13.045 8.43319 12.6693 8.78248 12.4012C9.13177 12.1332 9.55973 11.9879 10 11.9879C10.4403 11.9879 10.8682 12.1332 11.2175 12.4012C11.5668 12.6693 11.8179 13.045 11.9319 13.4703C12.0458 13.8956 12.0162 14.3466 11.8478 14.7533C11.6793 15.1601 11.3813 15.4999 11 15.72ZM15 8H5V7C5 5.67392 5.52678 4.40215 6.46447 3.46447C7.40215 2.52678 8.67392 2 10 2C11.3261 2 12.5979 2.52678 13.5355 3.46447C14.4732 4.40215 15 5.67392 15 7V8Z"
        fill="white"
      />
    </svg>
  );
};
