import { cn } from "@/utils/index";
import { LockedIcon } from "../sets/LockedIcon";
import { EyeIcon } from "@heroicons/react/24/solid";
import { ReactNode } from "react";

type Preview = {
  alt: string;
  src: string;
  name: string;
  displayName?: string;
  locked: boolean;
  lockedText?: string;
  lockedIcon?: "lock" | "eye";
  count?: number;
  beta?: boolean;
  onClick?: () => void;
};

type SetContainerProps = {
  setTitle?: string;
  previews?: Preview[];
  footerText?: ReactNode;
  onSetClick?: (preview: Preview) => void;
  selectedVariants: string[];
};

export const SetContainer = ({
  setTitle,
  previews,
  onSetClick,
  selectedVariants,
  footerText
}: SetContainerProps) => {
  const handleSetClick = (preview: Preview) => {
    onSetClick && onSetClick(preview);
  };

  return (
    <div className="mt-2 md:mt-3">
      <div className="flex flex-col items-center gap-x-8 rounded-lg bg-[#e7ed93] px-4 lg:flex-row">
        {setTitle && (
          <div className="text-md w-full flex-grow pt-2.5 text-left font-semibold lg:w-auto lg:pt-0 lg:text-center">
            {setTitle}
          </div>
        )}
        <div className="flex w-full flex-col lg:w-auto lg:min-w-[386px]">
          <div
            className={cn(
              "flex w-full flex-row gap-4 overflow-x-auto overflow-y-hidden rounded-lg py-4 md:flex-wrap lg:w-auto lg:min-w-[386px]",
              {
                "pt-3 lg:pt-4": !!setTitle,
                "pb-2": !!footerText
              }
            )}
          >
            {previews.map((preview) => (
              <div
                key={preview.displayName ?? preview.name}
                className="relative w-[105px] lg:w-[115px]"
              >
                {preview.count && preview.count > 0 ? (
                  <div
                    className={cn(
                      "bg-legend-purple absolute right-[-8px] top-[-8px] z-10 flex h-5 w-5 items-center justify-center rounded-full text-[11px] text-white shadow",
                      {
                        "text-[8px]": preview.count >= 100
                      }
                    )}
                  >
                    {preview.count}
                  </div>
                ) : null}
                <div className={selectedVariants.includes(preview.name) ? "" : "opacity-65"}>
                  <SelectedSetWrapper
                    onClick={() => (preview.onClick ? preview.onClick() : handleSetClick(preview))}
                    alt={preview.alt}
                    src={preview.src}
                    locked={preview.locked}
                    lockedText={preview.lockedText ?? "Win this"}
                    beta={preview.beta}
                    selected={selectedVariants.includes(preview.name)}
                    lockedIcon={preview.lockedIcon}
                  />
                </div>
                {preview.displayName && (
                  <p className="pt-2 text-center text-xs font-semibold">{preview.displayName}</p>
                )}
              </div>
            ))}
          </div>
          {footerText && footerText}
        </div>
      </div>
    </div>
  );
};

const SelectedSetWrapper = (props: PreviewSetProps) => {
  return (
    <div
      className={
        "relative h-[105px] w-[105px] cursor-pointer overflow-hidden rounded lg:h-[115px] lg:w-[115px]"
      }
      onClick={props.onClick}
    >
      <PreviewSet {...props} />
      {props.beta && (
        <div className="bg-legend-purple absolute left-[-26px] top-[4px] z-10 w-20 rotate-[-45deg] py-0.5 text-center">
          <p className="text-[10px] text-white">beta</p>
        </div>
      )}
      {props.selected && (
        <>
          <div className="absolute left-0 top-0 h-full w-full rounded border-[6px] border-white pb-2 md:border-8"></div>
          <div
            className={cn(
              "font-zendots absolute bottom-0 left-0 w-full select-none rounded bg-white py-1 text-center text-[0.58rem] uppercase"
            )}
          >
            {props.locked ? props.lockedText : "Selected"}
          </div>
        </>
      )}
    </div>
  );
};

interface CardDesignPreviewProps {
  src: string;
  alt: string;
  lockedIcon: "lock" | "eye";
}

interface PreviewSetProps extends CardDesignPreviewProps {
  locked: boolean;
  lockedText: string;
  beta: boolean;
  count?: number;
  onClick: () => void;
  selected?: boolean;
}
const PreviewSet = ({ locked, ...setProps }: PreviewSetProps) => {
  return (
    <div className="cursor-pointer overflow-hidden rounded">
      {locked ? <LockedSet {...setProps} /> : <CardDesignPreview {...setProps} />}
    </div>
  );
};

const LockedSet = (props: CardDesignPreviewProps) => {
  return (
    <div className="relative">
      <CardDesignPreview {...props} />
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
        {props.lockedIcon === "eye" ? (
          <EyeIcon className="h-6 w-6 text-white" />
        ) : (
          <LockedIcon className="h-6 w-5 text-white" />
        )}
      </div>
    </div>
  );
};

const CardDesignPreview = ({ src, alt }: CardDesignPreviewProps) => {
  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img alt={alt} src={src} className="h-[105px] w-[105px] lg:h-[115px] lg:w-[115px]" />
  );
};
