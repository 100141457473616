import { useEffect, useRef, useState } from "react";
import Button from "../../buttons/button";
import Container from "../../containers/container";
import { useCreateCardMutation } from "../../../hooks/card/useCreateCard";
import { useGetPlayerCard } from "../../../hooks/card/useGetPlayerCard";
import { LoadingCard } from "../../card/LoadingCard";
import { PlayerFlippableCard } from "@/components/card/PlayerFlippableCard";
import { useOrderFormStore } from "store/order-form/order-form";
import useScrollToTop from "hooks/use-scroll-to-top";
import ButtonFooter from "../button-footer";
import StepHeader from "../step-header";
import { cn } from "@/utils/index";
import { useUpdateCard } from "../about/use-update-card";
import { CardData } from "store/order-form/card";
import EditImage from "./edit-image";
import { v4 } from "uuid";
import useProgress from "./use-progress";
import { isEqual } from "lodash";
import useResetCard from "hooks/card/use-reset-card";
import { WorkspaceCardSetResponse } from "common/api/requests";
import { ConfirmModal, showToast } from "legend-ui";
import { isDefaultDesignForSet } from "@/utils/reward";
import { sendMetaEvent } from "@/utils/send-meta-event";
import { sendGAEvent } from "@next/third-parties/google";

type Props = {
  onNext: () => void;
  onBack: () => void;
  sets: WorkspaceCardSetResponse[];
  refetchSets: () => void;
};

const UploadOverview = ({ onBack, onNext, sets, refetchSets }: Props) => {
  useScrollToTop();
  const { setPhotoStep, setCardStep, cardStepData } = useOrderFormStore();
  const { refreshCard } = useUpdateCard();
  const { reset } = useResetCard(sets);
  const [imageBeingCropped, setImageBeingCropped] = useState<string>();
  const markedForRefresh = useRef(false);
  const photoStep = cardStepData.photoStep;
  const currentSetId = cardStepData.pickASetStep?.cardSetId;
  const currentAttributes = cardStepData.attributesStep?.selectedAttributes ?? [];
  const generatedWithAttributes = cardStepData.photoStep?.attributes ?? [];
  const generatedWithSetId = cardStepData.photoStep?.setId;
  const currentVariant = cardStepData.pickASetStep?.cardVariantBase;
  const generatedWithVariant = cardStepData.photoStep?.variant;
  const currentTemplate = cardStepData.pickASetStep?.cardSetTemplate;
  const generatedWithTemplate = cardStepData.photoStep?.template;
  const cardId = cardStepData.photoStep?.cardId;
  const cardData = photoStep?.cardData;
  const cardImage = photoStep?.cardImage;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { createCard, createCardVariant, getCachedVariant, isPending } = useCreateCardMutation();
  const { cardResult, isLoadingCard, isGeneratingCard } = useGetPlayerCard();
  const { progress, pollingForProgress, startPolling, stopPolling } = useProgress({
    cardResult
  });
  const [confirmLoseAttributes, setConfirmLoseAttributes] = useState(false);
  const cardGenerationFailed = cardResult?.card_processing_status === "failed";
  const showLoadingState = isPending || isGeneratingCard || isLoadingCard || pollingForProgress;
  const showPreview = !showLoadingState && cardResult?.card_preview;
  const doingInitialCardFetch =
    !cardResult && !isGeneratingCard && !pollingForProgress && isLoadingCard;

  const handleNext = () => {
    if (markedForRefresh.current) {
      markedForRefresh.current = false;
      refreshCard();
    }
    onNext();
  };

  const triggerFileInput = () => {
    fileInputRef?.current?.click();
  };

  const fileUploadWithReset = () => {
    reset();
    refetchSets();
    setConfirmLoseAttributes(false);
    triggerFileInput();
  };

  const handleFileUploadClick = () => {
    if (
      cardId &&
      (generatedWithAttributes.length > 0 ||
        !isDefaultDesignForSet(
          sets,
          generatedWithSetId,
          generatedWithTemplate,
          generatedWithVariant
        ))
    ) {
      setConfirmLoseAttributes(true);
      return;
    }
    triggerFileInput();
  };

  const isSupportedImage = (filename: string) => {
    return ["heic", "jpg", "jpeg", "png"].some((extension) =>
      filename.toLowerCase().endsWith(extension)
    );
  };

  const isSupportedForCropping = (filename: string) => {
    return ["jpg", "jpeg", "png"].some((extension) => filename.toLowerCase().endsWith(extension));
  };

  const startCardCreation = async (cardData: CardData) => {
    try {
      startPolling();
      const cardId = await createCard(cardData);
      setPhotoStep({
        cardId,
        cardImage: cardData.imageUrl,
        cardData,
        setId: currentSetId,
        template: currentTemplate,
        variant: currentVariant
      });
      sendMetaEvent("track", "CustomizeProduct");
      sendMetaEvent("trackCustom", "UploadPhoto");
      sendGAEvent("event", "view_item", {
        items: [
          {
            item_id: currentSetId,
            item_name: currentTemplate,
            item_variant: currentVariant
          }
        ]
      });
      sendGAEvent("event", "photo_uploaded");
    } catch (error) {
      stopPolling();
      showToast("We ran into an unexpected error. Please try again.");
    }
  };

  const onImageCrop = (url: string) => {
    const data: CardData = {
      imageUrl: url,
      fileName: `${v4()}.jpg`,
      mimeType: "image/jpeg"
    };
    startCardCreation(data);
    setImageBeingCropped(undefined);
    if (fileInputRef?.current) {
      fileInputRef.current.value = "";
    }
  };

  const onImageChange = async (e) => {
    e.preventDefault();

    const files = e.target?.files || e.dataTransfer?.files;
    const fileName = files?.[0]?.name;

    if (!files?.length) {
      return;
    }
    if (files.length !== 1 || !isSupportedImage(fileName)) {
      showToast("An unsupported photo type was selected. Supported types are: jpeg, png or heic.");
      return;
    }

    const file = files[0];
    const objectUrl = URL.createObjectURL(file);

    if (!isSupportedForCropping(fileName)) {
      showToast(
        "Skipped cropping step due to an unsupported photo type. Supported types for cropping are: jpeg, png.",
        { duration: 6000 }
      );

      const lowerCaseExtensionName = fileName
        .replace(/\.[^/.]+$/, (match) => match.toLowerCase())
        .replace("jpeg", "jpg");
      const imageData: CardData = {
        imageUrl: objectUrl,
        fileName: lowerCaseExtensionName,
        mimeType: file.type
      };

      startCardCreation(imageData);

      return;
    }

    setImageBeingCropped(objectUrl);
  };

  const ChangePhotoButton = () => (
    <Button className="flex-grow md:grow-0" onClick={handleFileUploadClick}>
      {cardImage ? "Change" : "Choose"}
    </Button>
  );

  const handleCancelGeneration = () => {
    stopPolling();
    setCardStep({
      photoStep: undefined
    });
  };

  const regenerateCardOnChange = async (
    currentCardId: string,
    newSetId: string,
    newTemplate: string,
    newVariant: string,
    newAttributes: string[]
  ) => {
    try {
      const cachedCardId = getCachedVariant(newSetId, newTemplate, newVariant, newAttributes);
      if (!cachedCardId) {
        startPolling();
      }
      const newCardId =
        cachedCardId ??
        (await createCardVariant(currentCardId, newSetId, newTemplate, newVariant, newAttributes));
      setPhotoStep({
        cardId: newCardId,
        setId: newSetId,
        template: newTemplate,
        variant: newVariant,
        attributes: newAttributes
      });
      markedForRefresh.current = true;
    } catch (error) {
      stopPolling();
      showToast("We ran into an unexpected issue. Please try again.");
    }
  };

  useEffect(() => {
    if (
      cardId &&
      generatedWithSetId &&
      (currentSetId !== generatedWithSetId ||
        currentTemplate !== generatedWithTemplate ||
        currentVariant !== generatedWithVariant ||
        !isEqual(currentAttributes, generatedWithAttributes))
    ) {
      regenerateCardOnChange(
        cardId,
        currentSetId,
        currentTemplate,
        currentVariant,
        currentAttributes
      );
    }
  }, [currentSetId, cardId, generatedWithSetId]);

  const renderCards = () => (
    <>
      {showLoadingState && (
        <LoadingCard
          progress={!doingInitialCardFetch ? progress?.value ?? 0 : undefined}
          showText={!doingInitialCardFetch}
          onCancel={!doingInitialCardFetch ? handleCancelGeneration : undefined}
        />
      )}
      {showPreview && <PlayerFlippableCard hideControls />}
      {!showLoadingState && !showPreview && cardGenerationFailed && (
        <p className="px-4 py-16 text-center">
          Woops, we ran into an issue processing your photo. Please try again, or try a different
          photo.
        </p>
      )}
    </>
  );

  return (
    <Container innerClassName="pt-0 px-0">
      <div className="flex flex-col gap-6 md:flex-row lg:gap-x-20">
        <div className="mb-12 flex flex-grow flex-col">
          <StepHeader title="Front: Upload Photo" subtitle="Upload a photo of the athlete" />
          <div className="flex flex-col justify-center px-4 pt-6 md:px-0 md:pt-8">
            <div
              className={cn("flex flex-col pb-4 md:pb-7", {
                "hidden md:flex": showPreview || showLoadingState
              })}
            >
              <div className="flex">
                <span className="text-3xl font-bold">*</span>
                <span className="pl-2 text-lg">Action shots are great!</span>
              </div>
              <div className="flex">
                <span className="text-3xl font-bold">*</span>
                <span className="pl-2 text-lg">No team photos, please (yet...)</span>
              </div>
              <div className="flex">
                <span className="text-3xl font-bold">*</span>
                <span className="pl-2 text-lg">Clean backgrounds recommended</span>
              </div>
            </div>
            <input
              ref={fileInputRef}
              type="file"
              className="hidden"
              accept={"image/jpeg, image/heic, image/heif, image/jpg, image/png"}
              onChange={onImageChange}
            />
            <div className="flex justify-center md:hidden">{renderCards()}</div>
            <ButtonFooter>
              <Button className="flex-grow md:grow-0" variant="outline" onClick={onBack}>
                Back
              </Button>
              <ChangePhotoButton />
              {cardData && (
                <Button
                  className="flex-grow md:grow-0"
                  disabled={showLoadingState}
                  onClick={handleNext}
                >
                  Next
                </Button>
              )}
            </ButtonFooter>
          </div>
        </div>

        <div className="hidden md:flex md:justify-end">
          <div>{renderCards()}</div>
        </div>
        <EditImage
          open={!!imageBeingCropped}
          imageUrl={imageBeingCropped}
          onComplete={onImageCrop}
          onClose={() => {
            setImageBeingCropped(undefined);
            if (fileInputRef?.current) {
              fileInputRef.current.value = "";
            }
          }}
        />
        <ConfirmModal
          title="Change photo?"
          description="The special design and attributes applied to your current card will be lost."
          onConfirm={fileUploadWithReset}
          onCancel={() => setConfirmLoseAttributes(false)}
          open={confirmLoseAttributes}
        />
      </div>
    </Container>
  );
};

export default UploadOverview;
