import React, { useEffect, useState } from "react";
import NavBar from "../../components/navigation/navbar";
import StepBar from "../../components/create/StepBar";
import UploadOverview from "../../components/create/upload/upload-overview";
import Container from "../../components/containers/container";
import AboutAthleteFront from "../../components/create/about/about-athlete-front";
import AboutAthleteBack from "@/components/create/about/about-athlete-back";
import SelectQuantity from "../../components/create/select-quantity";
import AddAttributes from "../../components/create/attributes/add-attributes";
import { PickASet } from "@/components/create/pick-a-set";
import useAuth from "../../hooks/useAuth";
import { ClientOnly, LoadingIndicator } from "legend-ui";
import { useOrderFormStore } from "store/order-form/order-form";
import { getAuth } from "common/utils/auth";
import { sendGAEvent } from "@next/third-parties/google";
import { StartGuestSessionDialog } from "@/components/create/guest-session-dialog";
import { useV1ServiceV1CardJamWorkspaceCardSetList } from "common/api/queries";
import { useSearchParams } from "next/navigation";

export enum CreateStep {
  Pick = 0,
  AddAttributes = 1,
  Upload = 2,
  AboutFront = 3,
  AboutBack = 4,
  SelectQuantity = 5,
  Review = 6
}

export const stepEventName: Record<CreateStep, string> = {
  [CreateStep.Pick]: "pick_a_set_step",
  [CreateStep.AddAttributes]: "add_attributes_step",
  [CreateStep.Upload]: "upload_photo_step",
  [CreateStep.AboutFront]: "about_front_step",
  [CreateStep.AboutBack]: "about_back_step",
  [CreateStep.SelectQuantity]: "select_quantity_step",
  [CreateStep.Review]: "review_step"
};

type InitialStep = {
  step: CreateStep;
  index: number;
};

const CreateACard = () => {
  const [initialStep, setInitialStep] = useState<InitialStep>();

  useEffect(() => {
    const orderForm = useOrderFormStore.getState();
    const auth = getAuth();

    if (
      !!orderForm?.workspaceId &&
      !!auth?.workspaceId &&
      orderForm?.workspaceId !== auth?.workspaceId
    ) {
      useOrderFormStore.getState().clear();
      setInitialStep({
        step: CreateStep.Pick,
        index: CreateStep.Pick
      });
      return;
    }

    if (orderForm.cardStepData?.confirmStep?.confirmedCardId) {
      setInitialStep({
        step: CreateStep.SelectQuantity,
        index: CreateStep.SelectQuantity
      });
      return;
    }

    setInitialStep({
      step: CreateStep.Pick,
      index: CreateStep.Pick
    });
  }, []);

  if (!initialStep) {
    return null;
  }

  return <CreateACardStepDisplay {...initialStep} />;
};

const CreateACardStepDisplay = (initialStepProps: InitialStep) => {
  const [step, setStep] = useState(initialStepProps.step);
  const [stepIndex, setStepIndex] = useState(initialStepProps.index);
  const [showSessionDialog, setShowSessionDialog] = useState(false);
  const { session, isLoadingSession, logout } = useAuth();
  const workspaceId = session?.workspaceId;
  const referredEmail = new URLSearchParams(window.location.search).get("email");
  const {
    data: setResponse,
    isLoading: setsLoading,
    refetch: refetchSets
  } = useV1ServiceV1CardJamWorkspaceCardSetList(
    {
      page: 1,
      workspaceId
    },
    undefined,
    {
      enabled: !isLoadingSession && !!workspaceId
    }
  );
  const sets = setResponse?.results ?? [];
  const params = useSearchParams();
  const flow = params.get("flow");

  const navigate = (step: CreateStep, stepIndex: number) => {
    setStep(step);
    setStepIndex(stepIndex);
  };

  const isClient = typeof window !== "undefined";

  useEffect(() => {
    const isSessionExpired = session?.expiresAt && Number(session.expiresAt) * 1000 < Date.now();
    const shouldRestartSession = isClient && session && isSessionExpired;

    if (shouldRestartSession) {
      logout();
    }
  }, [session, isClient, isLoadingSession]);

  const doesNotHaveSession = isClient && !session;

  let stepDisplay;

  switch (step) {
    case CreateStep.Pick:
      stepDisplay = (
        <PickASet
          onNext={() => navigate(CreateStep.AddAttributes, 1)}
          sets={sets}
          setsLoading={setsLoading}
          refetchSets={refetchSets}
        />
      );
      break;
    case CreateStep.AddAttributes:
      stepDisplay = (
        <AddAttributes
          onBack={() => navigate(CreateStep.Pick, 0)}
          onNext={() => navigate(CreateStep.Upload, 2)}
          sets={sets}
          refetchSets={refetchSets}
        />
      );
      break;
    case CreateStep.Upload:
      stepDisplay = (
        <UploadOverview
          onBack={() => navigate(CreateStep.AddAttributes, 1)}
          onNext={() => navigate(CreateStep.AboutFront, 3)}
          sets={sets}
          refetchSets={refetchSets}
        />
      );
      break;
    case CreateStep.AboutFront:
      stepDisplay = (
        <AboutAthleteFront
          onBack={() => navigate(CreateStep.Upload, CreateStep.Upload)}
          onNext={() => navigate(CreateStep.AboutBack, CreateStep.AboutBack)}
        />
      );
      break;
    case CreateStep.AboutBack:
      stepDisplay = (
        <AboutAthleteBack
          onBack={() => navigate(CreateStep.AboutFront, CreateStep.AboutFront)}
          onNext={() => navigate(CreateStep.SelectQuantity, CreateStep.SelectQuantity)}
        />
      );
      break;
    case CreateStep.SelectQuantity:
      stepDisplay = <SelectQuantity />;
      break;
    case CreateStep.Review:
      stepDisplay = <div />;
      break;
    default:
      break;
  }

  const shouldShowStep = !isLoadingSession && !!session;

  useEffect(() => {
    if (step !== undefined && shouldShowStep) {
      sendGAEvent("event", stepEventName[step]);
    }
  }, [step, shouldShowStep]);

  useEffect(() => {
    if (doesNotHaveSession) {
      setShowSessionDialog(true);
    }
  }, [doesNotHaveSession]);

  return (
    <div className="flex min-h-screen flex-col md:pb-4">
      <NavBar />
      <StepBar stepIndex={stepIndex} flow={flow} />
      <ClientOnly>
        <div className="relative flex flex-grow flex-col">
          <div className="absolute bottom-[100px] right-1 z-0 hidden select-none md:block">
            <div className="flex gap-x-5 text-5xl leading-[0.9]">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
            <div className="flex gap-x-5 text-5xl leading-[0.9]">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
            <div className="flex gap-x-5 text-5xl leading-[0.9]">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>{" "}
          </div>
          {shouldShowStep && stepDisplay}
          {!shouldShowStep && (
            <Container>{isLoadingSession ? <LoadingIndicator variant="black" /> : null}</Container>
          )}
          <StartGuestSessionDialog
            open={showSessionDialog}
            referredEmail={referredEmail}
            onClose={() => setShowSessionDialog(false)}
          />
        </div>
      </ClientOnly>
    </div>
  );
};

export default CreateACard;
