export const EmptyState = () => {
  return (
    <div className="rounded-md bg-[#e7ed93] p-3 md:p-4">
      <div className="flex flex-row items-center space-x-4">
        <LightBulbIcon />
        <div>
          <p className="font-semibold">Win special designs and attributes</p>
          <p>Complete your order for a chance to win!</p>
        </div>
      </div>
    </div>
  );
};

const LightBulbIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_279_1381)">
        <path
          d="M10.588 24C10.192 22.3027 8.40534 20.9147 7.67201 20C6.41626 18.4311 5.62927 16.5393 5.40169 14.5426C5.17412 12.546 5.51521 10.5256 6.38568 8.71432C7.25615 6.90304 8.62059 5.37449 10.3218 4.30477C12.023 3.23506 13.9919 2.66768 16.0014 2.668C18.011 2.66832 19.9797 3.23633 21.6806 4.30659C23.3814 5.37686 24.7454 6.90584 25.6153 8.7174C26.4852 10.529 26.8256 12.5494 26.5974 14.546C26.3692 16.5426 25.5816 18.4341 24.3253 20.0027C23.592 20.916 21.808 22.304 21.412 24H10.588ZM21.3333 26.6667V28C21.3333 28.7072 21.0524 29.3855 20.5523 29.8856C20.0522 30.3857 19.3739 30.6667 18.6667 30.6667H13.3333C12.6261 30.6667 11.9478 30.3857 11.4477 29.8856C10.9476 29.3855 10.6667 28.7072 10.6667 28V26.6667H21.3333ZM17.3333 13.34V8L11.3333 16.0067H14.6667V21.34L20.6667 13.34H17.3333Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_279_1381">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
