import { FlippablePreview } from "legend-ui/components/card/flippable-preview";

export const PreviewFlippableCard = ({ frontUrl, backUrl }) => {
  return (
    <div className="relative flex flex-col justify-center text-center text-white">
      <FlippablePreview
        front={{
          alt: "Card Preview front",
          src: frontUrl,
          className: "w-full h-full"
        }}
        back={{
          alt: "Card Preview back",
          src: backUrl,
          className: "w-full h-full"
        }}
      />
    </div>
  );
};
