import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, DialogTitle } from "@radix-ui/react-dialog";
import { AthleteInfoView } from "common/api/requests";
import { cn } from "common/utils";
import { Button, DialogContent, DialogDescription, DialogHeader, Input } from "legend-ui";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const skinTones: Record<AthleteInfoView["skin_tone_selected"], string> = {
  fair_light: "Fair - Light",
  medium_tan: "Medium - Tan",
  dark_deep: "Dark - Deep"
};

const schema = yup.object({
  skinTone: yup.string().oneOf(["fair_light", "medium_tan", "dark_deep"]).optional(),
  gender: yup.string().optional()
});

export type AnimeOptions = {
  skinTone?: AthleteInfoView["skin_tone_selected"];
  gender?: string;
};

type Props = {
  onSubmit: (options: AnimeOptions) => void;
  onSkip: () => void;
  open: boolean;
  defaultOptions?: AnimeOptions;
};

export function AnimeOptionsModal({ onSubmit, onSkip, open, defaultOptions }: Props) {
  const { register, handleSubmit, watch } = useForm<AnimeOptions>({
    resolver: yupResolver(schema)
  });
  const skinTone = watch("skinTone");

  return (
    <Dialog open={open}>
      <DialogContent
        className="flex min-h-[240px] w-[400px] flex-col items-center justify-center !rounded-3xl !border-none bg-[#914dec]"
        hideClose={true}
      >
        <DialogHeader>
          <DialogTitle className="text-center text-lg font-semibold text-white">
            Anime options
          </DialogTitle>
          <DialogDescription className="text-center text-sm text-white">
            Our anime attribute is experimental. These parameters may help you get a better result.
          </DialogDescription>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col items-center justify-center gap-y-5 pt-2 text-left">
              <div className="grid w-full grid-cols-2 gap-3">
                <Input
                  labelClassName="text-white text-sm"
                  label="Gender"
                  placeholder="Gender"
                  defaultValue={defaultOptions?.gender}
                  {...register("gender")}
                />
                <div className="flex flex-col">
                  <label htmlFor="skin-tone" className="inline-block pb-2 text-sm text-white">
                    Skin tone
                  </label>
                  <div className="relative">
                    <select
                      id="skin-tone"
                      className={cn(
                        "w-full cursor-pointer appearance-none rounded-full border border-gray-300 bg-white px-4 py-2 focus:bg-white",
                        {
                          "text-gray-400": !skinTone && !defaultOptions?.skinTone
                        }
                      )}
                      {...register("skinTone")}
                      defaultValue={defaultOptions?.skinTone ?? ""}
                    >
                      <option value="" disabled hidden>
                        Skin tone
                      </option>
                      {...Object.entries(skinTones).map(([tone, label]) => (
                        <option key={tone} value={tone}>
                          {label}
                        </option>
                      ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
                      <svg
                        className="h-4 w-4 text-gray-600"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col gap-y-2">
                <Button type="submit" className="w-full">
                  Ok
                </Button>
                <Button onClick={onSkip} variant="transparent" className="w-full">
                  Skip
                </Button>
              </div>
            </div>
          </form>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
