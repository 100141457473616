import { WorkspaceCardSetResponse } from "common/api/requests";
import { showToast } from "legend-ui";
import { useOrderFormStore } from "store/order-form/order-form";

type ResetCard = {
  reset: () => void;
};

export default function useResetCard(sets: WorkspaceCardSetResponse[]): ResetCard {
  const { setCardStep } = useOrderFormStore();

  const reset = (): boolean => {
    const firstDefaultSet = sets.find((s) =>
      s.card_set_template_variant_bases.some((v) => v.default)
    );
    const firstDefaultDesign = firstDefaultSet?.card_set_template_variant_bases?.find(
      (v) => v.default
    );
    if (!firstDefaultSet || !firstDefaultDesign) {
      showToast("We ran into an issue resetting your card. Please try again.");
      return false;
    }
    setCardStep({
      pickASetStep: {
        cardSetId: firstDefaultSet.id,
        cardSetVariantName: firstDefaultDesign.name,
        cardSetTemplate: firstDefaultDesign.card_set_template,
        cardVariantBase: firstDefaultDesign.card_variant_base
      },
      attributesStep: {
        selectedAttributes: [],
        cardSetId: firstDefaultSet.id
      },
      photoStep: {
        setId: firstDefaultSet.id,
        template: firstDefaultDesign.card_set_template,
        variant: firstDefaultDesign.card_variant_base,
        attributes: []
      }
    });
    return true;
  };

  return { reset };
}
